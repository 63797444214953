import { classnames } from "Utils/Classnames.Util";

const RotateHighlightText = ({
  content,
  className,
}: {
  content: string;
  className?: string | undefined;
}) => {
  return (
    <span
      className={classnames(
        "inline-block -rotate-[4deg] rounded-full bg-black px-4 text-white",
        className,
      )}
    >
      {content}
    </span>
  );
};

export default RotateHighlightText;
