import { useEffect } from "react";
import { Router } from "next/router";
import NProgress from "nprogress";
import { Sora } from "next/font/google";
import { StickyContainer } from "react-sticky";
import { GoogleAnalytics } from "nextjs-google-analytics";
import { HubspotProvider } from "next-hubspot";

import "Locales";
import { PAGE_THEME } from "Constants/Common.Constant";
import ErrorBoundary from "Providers/ErrorBoundary.Provider";
import { LayoutHeader, LayoutFooter } from "Components/Own";
import ThemeProvider from "Context/Theme.Context";
import { classnames } from "Utils/Classnames.Util";

import "nprogress/nprogress.css";
import "../styles/globals.scss";

const sora = Sora({
  subsets: ["latin"],
  weight: ["200", "300", "400", "500", "600", "700", "800"],
  style: ["normal"],
});

const HelloCleverLandingPage = ({ Component, pageProps }: any) => {
  useEffect(() => {
    const handleRouteStart = () => NProgress.start();
    const handleRouteDone = () => NProgress.done();

    Router.events.on("routeChangeStart", handleRouteStart);
    Router.events.on("routeChangeComplete", handleRouteDone);
    Router.events.on("routeChangeError", handleRouteDone);

    return () => {
      // Make sure to remove the event handler on unmount!
      Router.events.off("routeChangeStart", handleRouteStart);
      Router.events.off("routeChangeComplete", handleRouteDone);
      Router.events.off("routeChangeError", handleRouteDone);
    };
  }, []);

  return (
    <>
      <GoogleAnalytics
        debugMode={process.env.NODE_ENV === "development"}
        trackPageViews={{ ignoreHashChange: true }}
        gaMeasurementId={process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID}
      />

      <ErrorBoundary>
        <StickyContainer>
          <ThemeProvider
            initValue={{ theme: pageProps.theme || PAGE_THEME.PRIMARY }}
          >
            <div
              className={classnames(
                "flex min-h-screen flex-col",
                sora.className,
              )}
            >
              <LayoutHeader />

              <HubspotProvider>
                <Component {...pageProps} />
              </HubspotProvider>

              <LayoutFooter />
            </div>
          </ThemeProvider>
        </StickyContainer>
      </ErrorBoundary>
    </>
  );
};

export default HelloCleverLandingPage;
