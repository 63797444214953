import { useRouter } from "next/router";
import { memo, useState } from "react";

import { NextLink } from "Components/Lib/Next";
import { TRACKING_EVENTS } from "Constants/Event.Constant";
import { MENUS } from "Constants/Menu.Constant";
import { logAnalyticEvent } from "Utils/Analytics.Util";
import { classnames } from "Utils/Classnames.Util";
import { localesText } from "Utils/LocalesText.Util";

import AppDownloadButton from "../../../AppDownloadButton";
import Button from "../../../Button";
import { Typography } from "../../../Typography";

import { PATHS_NAME } from "Constants/PathName.Constant";
import BusinessCollapse from "./BussinessCollapse";
import MenuItem from "./MenuItem";
import ShopperCollapse from "./ShopperCollapse";
import "rc-drawer/assets/index.css";
import ResourceCollapse from "./ResourcesCollapse";

const DrawerContent = ({ closeDrawer }: { closeDrawer: () => void }) => {
  const router = useRouter();
  const [expandedStates, setExpandedStates] = useState<boolean[]>(
    new Array(MENUS.length).fill(false),
  );

  const onToggle = (index: number) => {
    setExpandedStates((prevStates) =>
      prevStates.map((isExpanded, i) => (i === index ? !isExpanded : false)),
    );
  };

  return (
    <div className="flex h-full w-full flex-col pb-2 gap-5">
      <div className="flex w-full justify-between items-center px-4">
        <NextLink href={PATHS_NAME.HOME}>
          <div className="small-logo-container">
            <div className="black-logo-image" />
          </div>
        </NextLink>

        <button
          onClick={(event) => {
            event.preventDefault();
            closeDrawer();
          }}
          className="p-2"
        >
          <img
            src="/Assets/Svg/close-icon.svg"
            width={12}
            height={12}
            alt="close-icon"
          />
        </button>
      </div>

      <div className="w-full h-full overflow-y-auto flex flex-col justify-between">
        <div className="flex flex-1 flex-col p-1">
          <ul className="menu">
            {MENUS.map((menu, index) => {
              if (menu.hasSubmenu) {
                if (menu.isForShoppers) {
                  return (
                    <ShopperCollapse
                      isExpanding={expandedStates[index]}
                      onToggle={() => onToggle(index)}
                      key={menu.id}
                      menu={menu}
                      index={index}
                      currentPath={router.asPath}
                    />
                  );
                }

                if (menu.isForBusiness) {
                  return (
                    <BusinessCollapse
                      isExpanding={expandedStates[index]}
                      onToggle={() => onToggle(index)}
                      key={menu.id}
                      menu={menu}
                      index={index}
                      currentPath={router.asPath}
                    />
                  );
                }

                if (menu.isResources) {
                  return (
                    <ResourceCollapse
                      isExpanding={expandedStates[index]}
                      onToggle={() => onToggle(index)}
                      key={menu.id}
                      menu={menu}
                      index={index}
                      currentPath={router.asPath}
                    />
                  );
                }
              } else {
                return (
                  <MenuItem
                    key={menu.id}
                    menu={menu}
                    active={router.asPath === menu.url}
                  />
                );
              }
            })}
          </ul>
        </div>

        <div className="flex flex-col gap-5 px-4">
          <AppDownloadButton
            theme="dark"
            showSeperate
            important={false}
            className="flex justify-center gap-3"
          />

          <NextLink
            href={PATHS_NAME.GET_IN_TOUCH}
            onClick={() => {
              logAnalyticEvent({ key: TRACKING_EVENTS.usingTypeformData });
            }}
          >
            <Button
              className={classnames(
                "h-12 w-full rounded-full border-none bg-clever-green text-clever-black",
              )}
            >
              <Typography.Content shouldScale={false}>
                {localesText("common.say_hello")}
              </Typography.Content>
            </Button>
          </NextLink>
        </div>
      </div>
    </div>
  );
};

export default memo(DrawerContent);
