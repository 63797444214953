import { useCallback } from "react";

import { RESOURCES_SUBMENUS, RESOURCES_URL } from "Constants/Menu.Constant";
import { classnames } from "Utils/Classnames.Util";

import { Typography } from "../../../Typography";

import { PATHS_NAME } from "Constants/PathName.Constant";
import classNames from "classnames";
import MenuItem from "./MenuItem";

const ResourceCollapse = ({
  menu,
  currentPath,
  index,
  isExpanding,
  onToggle,
}: {
  menu: { id: number; label: string; url: string };
  currentPath: string;
  index: number;
  isExpanding: boolean;
  onToggle: (index: number) => void;
}) => {
  const checkActiveMenu = useCallback(
    (url: string) => {
      if (url === PATHS_NAME.HOME) return false;

      return (
        RESOURCES_URL.some((resourceUrl) =>
          currentPath.startsWith(resourceUrl),
        ) && currentPath.includes(url)
      );
    },
    [currentPath],
  );

  return (
    <div
      tabIndex={index}
      className={classnames("flex flex-col text-clever-black cursor-pointer")}
    >
      <li
        suppressHydrationWarning={true}
        onClick={(event) => {
          event.stopPropagation();
          onToggle(index);
        }}
        className={classnames("min-h-0 p-0", {
          "text-clever-link font-medium":
            RESOURCES_URL.some((resourceUrl) =>
              currentPath.startsWith(resourceUrl),
            ) && currentPath.includes(currentPath),
        })}
      >
        <Typography.Heading3 className="flex justify-between">
          {menu.label}
          <img
            src="Assets/Svg/arrow-right.svg"
            alt="arrow"
            className={classNames({ "rotate-90": isExpanding && index === 2 })}
          />
        </Typography.Heading3>
      </li>

      {isExpanding && index === 2 && (
        <div className="ml-3">
          <ul className="my-2">
            <Typography.Description className="menu-title font-semibold text-clever-description">
              {RESOURCES_SUBMENUS.title}
            </Typography.Description>

            {RESOURCES_SUBMENUS.submenu.map((submenu) => {
              return (
                <MenuItem
                  key={submenu.id}
                  menu={submenu}
                  active={checkActiveMenu(submenu.url)}
                />
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default ResourceCollapse;
