import common from "./Common";
import home from "./Home";
import errors from "./Errors";
import buy_to_earn from "./BuyToEarn";
import about from "./About";
import shops from "./Shops";
import merchant_api from "./MerchantApi";
import terms from "./Terms";
import policy from "./Policy";
import contact from "./Contact";
import qr_code from "./QrCode";
import payto from "./Payto";
import status from "./Status";
import xero from "./Xero";
import tyro from "./Tyro";
import payment_request from "./PaymentRequest";
import in_store from "./InStore";
import for_shoppers from "./ForShoppers";

const locale = {
  errors,
  common,
  home,
  buy_to_earn,
  about,
  shops,
  merchant_api,
  terms,
  policy,
  contact,
  qr_code,
  payto,
  status,
  xero,
  tyro,
  payment_request,
  in_store,
  for_shoppers,
};

export default locale;
