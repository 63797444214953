const about = {
  empowering: "We are empowering",
  everyone: "everyone",
  about_empowering:
    "Hello Clever is a buy-to-earn ecosystem that is empowering young Aussies to manage their money Clever, shop Clever and pay Clever. With the cost of living rising rapidly, it’s time to get Clever with your money.",
  who_is_behind: "Our story at",
  made_of_ex_bankers:
    "Hello Clever exists to help our customers create a healthier relationship with their money and make smarter decisions.",
  more_than_just_words:
    "We are a global team of over 20 people spread across 10 cities and 6 countries, made of ex-bankers, and fintech advocates with banking and commercial mindsets. We are a team of qualified, experienced and ambitious people who are completely dedicated to our mission to help millions of people feel good about their money and achieve their lifetime goals quicker.",
  work_with_us: "Work with us",
  trusted_by: "We are trusted by",
  merchants: "merchants",
  by_supporting_merchants:
    "By supporting merchants to operate their payments securely with PayID, PayTo, and all major Cards, we help them to boost sales, improve cash flow, prevent fraud, and reduce chargebacks.",
  become_merchant: "Become a Hello Clever Merchant",
  it_pays_to_be: "It pays to be",
  hc_is_transforming:
    "Hello Clever is transforming the way people experience payments. We’re on a mission to make every transaction smarter, faster, and more rewarding. Through real-time payments and instant cashback, Hello Clever provides businesses and consumers with a seamless, customer-centric payment experience that drives engagement and loyalty.",
  clever: "clever",
};

export default about;
