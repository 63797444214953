import dynamic from "next/dynamic";

const LayoutFooter = dynamic(() => import("./Footer.Container"), {
  // loading: () => <SuspendFooter />,
  ssr: false,
});

// const SuspendFooter = () => {
//   return (
//     <footer className="flex justify-center bg-dark px-[4%] pb-20 pt-24 sm:px-[3%] md:px-[2%] absolute top-0 bottom-0 right-0" />
//   );
// };

export default LayoutFooter;
