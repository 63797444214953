import { classnames } from "Utils/Classnames.Util";

const Button = ({
  disabled,
  loading,
  shape = "square",
  status = "none",
  variant = "contained",
  buttonType = "none",
  buttonSize = "normal",
  type,
  noAnimation,
  className,
  wide,
  glassEffect,
  ...rest
}: MyButton) => {
  return (
    <button
      {...rest}
      className={classnames(
        "btn border-none text-base font-medium normal-case",
        buttonStatuses[status],
        buttonTypes[buttonType],
        buttonVariants[variant],
        buttonShapes[shape],
        buttonSizes[buttonSize],
        {
          "no-animation": noAnimation,
          loading,
          "btn-wide": wide,
          glass: glassEffect,
          "h-14 min-h-[h-14]": buttonSize === "large",
        },
        className,
      )}
      disabled={disabled}
    />
  );
};

export interface MyButton extends React.HTMLProps<HTMLButtonElement> {
  status?: "none" | "info" | "success" | "warning" | "error";
  variant?: "contained" | "outlined";
  shape?: "square" | "circle";
  loading?: boolean;
  noAnimation?: boolean;
  buttonType?:
    | "none"
    | "primary"
    | "secondary"
    | "accent"
    | "ghost"
    | "link"
    | "primary_dark";
  buttonSize?: "large" | "normal" | "small" | "tiny";
  wide?: boolean;
  glassEffect?: boolean;
}

const buttonStatuses = {
  info: "btn-info",
  success: "btn-success",
  warning: "btn-warning",
  error: "btn-error",
  none: "",
};

const buttonTypes = {
  primary: "bg-clever-primary",
  primary_dark: "bg-dark",
  secondary: "btn-secondary",
  accent: "btn-accent",
  ghost: "btn-ghost",
  link: "btn-link",
  none: "",
};

const buttonVariants = {
  contained: "",
  outlined: "btn-outline",
};

const buttonShapes = {
  square: "",
  circle: "btn-circle",
};

const buttonSizes = {
  normal: "h-12 min-h-12",
  large: "btn-lg h-14",
  small: "btn-sm",
  tiny: "btn-xs",
};

export default Button;
