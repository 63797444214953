const payment_request = {
  your_way_title: "your way",
  instant_payment: "Instant payment requests",
  fast_payment:
    "Initiate fast payment requests directly from your Hello Clever dashboard with a single link via SMS or email.",
  customer_can_pay:
    "Customers can pay you with PayID, PayTo or card, ensuring instant and swift payments to you.",

  //section2
  payment: "payment",
  collection: "collection",
  simple_payment_request: "Simple payment requests",
  minimise_missed_payments: "Minimise missed payments",
  boost_cash_flow: "Boost with your cash flow",
  designed_for: "Designed for your convenience and simple for your customers.",
  no_need:
    "No need for complex integrations or APIs. Just send a payment request link and get paid effortlessly.",
  rewards_in_store: "\nRewards in-store is here",
  real_cash: "Get real cash - instantly",
  pointless_points: "No more pointless points",
  tap_and_pay: "Just tap and pay as usual",
  instant_payments: "Instant payments with PayID or PayTo",
  skip_frustration:
    "Skip the frustration of accumulating unused  points, and enjoy real cash rewards that you'll actually love.",
  simply_tap:
    "Simply tap your bank card as usual and enjoy instant in-store cashback without any extra steps or hassle. It's that easy!",

  //section3
  send: "send",
  payments: "payments",
  customise: "Customise",
  how_you: "how you",
  link_bank_card:
    "Link your everyday bank card to the Hello Clever app and start earning instant cashback at our partnered cafes, restaurants, and stores!",
  payment_method:
    "Select your preferred method for sending payment requests, whether through SMS or email, and customise the expiration date according to your preference.",

  //section4
  streamlined: "Streamlined",
  management: "management",
  at_your_fingertips: "at your fingertips",
  effortlessly:
    "Effortlessly manage payment requests, track pending and received transactions, and stay in control—all directly from your Hello Clever dashboard.",

  //section5
  security: "Security",
  you_can_rely_on: "you can rely on",
  feel_confident:
    "Feel confident with Hello Clever’s payment requests – ensuring swift, secure transactions that meet the highest security standards. Get paid fast, and trust us to handle security.",

  //section6
  enjoy: "Enjoy",
  your_way: "\nyour way",
  stay_on_top:
    "Stay on top of the latest instant cashback offers and irresistible deals in our app.",
  track_your_earnings:
    "Track your earnings growth in your Hello Clever wallet.",
  cash_out_your_rewards:
    "Cash out your rewards to your bank account or use it to put towards future purchases.",
  how: "How",
  it_works: "it works",
};

export default payment_request;
